import type { Ref } from 'vue'
import type { PageQuery } from '#gql'

export const useStatamicPage = async () => {
  const formatError = (error: unknown) => {
    return createError({
      fatal: true,
      // @ts-ignore
      statusCode: error?.status ?? 500,
      message: error instanceof Error ? error.message : 'An error occurred',
    })
  }

  let page: Ref<Enforce<PageQuery['entry']>>
  try {
    const { entry } = await useInjectableEntry()
    useSeotamic(entry)
    useHead({
      titleTemplate: (title?: any) => `${process.dev ? '🚧 ' : ''} ${title}`,
    })
    page = entry as Ref<Enforce<PageQuery['entry']>>

    let path = ''
    useBreadcrumbs(
      page.value?.breadcrumbs.map((item) => {
        if (item.slug !== 'home') {
          path = path + '/' + item.slug
        }
        return {
          name: item.title,
          path: item.slug === 'home' ? '/' : path,
        }
      }) ?? []
    )

    // const router = useRouter()
    // router.beforeResolve(async (route) => {
    //   await refresh(route).catch((error) => {
    //     // trick vou-router into complete the navigation
    //     // in order to update the url and create stack entry
    //     setTimeout(() => showError(formatError(error)), 0)
    //   })
    // })
  } catch (error) {
    // check for not found

    // @ts-ignore
    if (error.status === 404) {
      const runtimeConfig = useRuntimeConfig()
      const base = runtimeConfig.public.API_URL.replace('/api', '')
      let test: any
      try {
        const route = useRoute()
        test = await fetch(base + route.path, { redirect: 'manual' })
      } catch (e) {}
      if (test && test.status >= 300 && test.status < 400) {
        const url = new URL(test.headers.get('location') ?? '/', base)
        const to = url.pathname + url.search + url.hash
        await navigateTo(to, { redirectCode: test.status })
      }
    }

    // other error
    throw formatError(error)
  }

  return {
    page,
  }
}
